<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-xl"
    shadow
    backdrop
    right
    title="CREATE CREDITOR"
    header-class="text-primary mt-2"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default>
      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form class="px-2">
          <basic-information-creditors
            ref="refBasicInformationCreditors"
            :user-data="userData"
          />
          <more-information-creditors
            @update:schedule="($event) => (userData.schedule = $event)"
            ref="refMoreInformationCreditors"
            :user-data="userData"
          />
        </b-form>
      </validation-observer>
    </template>

    <template #footer>
      <b-row class="px-3 py-2">
        <b-col lg="6">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="info"
            @click="
              resetuserData();
              $refs.refFormObserver.reset();
            "
            >Reset</b-button
          >
        </b-col>
        <b-col lg="6">
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="
                resetuserData();
                $refs.refFormObserver.reset();
                $emit('update:is-add-new-user-sidebar-active', false);
              "
              >Cancel</b-button
            >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-2"
              @click="onSubmit"
              >Save
            </b-button>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import formValidation from "@core/comp-functions/forms/form-validation";
import BasicInformationCreditors from "./BasicInformationCreditors.vue";
import MoreInformationCreditors from "@/views/debt-solution/views/creditors/components/sidebar/MoreInformationCreditors.vue";
import CreditorsService from "../../service/creditors.service";
export default {
  name: "AddNewCreditor",
  components: {
    BasicInformationCreditors,
    CreditorsService,
    MoreInformationCreditors,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const resetRowData = () => {};
    const { getValidationState, resetForm } = formValidation(resetRowData);
    return {
      getValidationState,
      resetForm,
      userData: {
        agent: "",
        offer_contact_number: "",
        creditorParentId: "",
        creditorParent: "",
        creditorName: "",
        recoveryName: "",
        //recoveryPhone: [{ phone: "" }],
        recoveryFax: [{ fax: "" }],
        recoveryEmail: [{ email: "" }],
        customerPhone: [{ phone: "" }],
        customerEmail: [{ email: "" }],
        customerFax: [{ fax: "" }],
        customerSettlement: "",
        customerSettle: "",
        customerWeb: "",
        creditorMailingAddress: "",
        address: "",
        creditorCity: null,
        creditorState: null,
        creditorZipCode: null,
        creditorCountry: null,
        timezone_id: null,
        schedule: [],
        send_types: [],
        agency: "",
        creditor_website: "",
        average_trade_percentage: "",
        interest_rate: "",
        customerName: "",

        city_address: null,
        zip_code_address: null,
        country_address: null,
        state_address: null,

        recovery_phone: [{ phone: "" }],
        recovery_agent: [{ agent: "", extension: "" }],
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      A_UPDATE_TABLE_CREDITORS:
        "DebtSolutionCreditors/A_UPDATE_TABLE_CREDITORS",
    }),
    resetuserData() {
      this.$emit("update_key", Math.random());
    },
    async onSubmit() {
      if (await this.$refs.refFormObserver.validate()) {
        try {
          this.addPreloader();
          await CreditorsService.SAVE_CREDITORS({
            id_creditor_parent: this.userData.creditorParentId,
            namecreditor: this.userData.creditorName,
            fax: this.userData.recoveryFax[0].fax
              ? this.userData.recoveryFax
              : null,
            email: this.userData.recoveryEmail[0].email
              ? this.userData.recoveryEmail
              : null,
            settlement: this.userData.customerSettlement,
            settle: this.userData.customerSettle,
            website: this.userData.customerWeb,
            creditorMailingAddress: this.userData.creditorMailingAddress,
            creditorAddress: this.userData.address,
            creditorCity: this.userData.creditorCity,
            creditorState: this.userData.creditorState,
            creditorZipCode: this.userData.creditorZipCode,
            creditorCountry: this.userData.creditorCountry,
            user: this.currentUser.user_id,
            id_credential: this.userData.idcredential
              ? this.userData.idcredential
              : (this.userData.idcredential = null),

            city_address: this.userData.city_address,
            zip_code_address: this.userData.zip_code_address,
            country_address: this.userData.country_address,
            state_address: this.userData.state_address,

            // new data
            debt_types: this.userData.debt_types,
            // customer data
            customer_name: this.userData.customerName,
            agent: this.userData.agent,
            offer_contact_number: this.userData.offer_contact_number,
            customer_phones: this.userData.customerPhone[0].phone
              ? this.userData.customerPhone
              : null,
            customer_faxs: this.userData.customerFax[0].fax
              ? this.userData.customerFax
              : null,
            customer_emails: this.userData.customerEmail[0].email
              ? this.userData.customerEmail
              : null,
            // recovery data
            recovery_name: this.userData.recoveryName,
            recovery_faxs: this.userData.recoveryFax[0].fax
              ? this.userData.recoveryFax
              : null,
            recovery_emails: this.userData.recoveryEmail[0].email
              ? this.userData.recoveryEmail
              : null,
            recovery_agent: this.userData.recovery_agent,
            recovery_phone: this.userData.recovery_phone,
            // more information
            timezone_id: this.userData.timezone_id,
            schedule: this.userData.schedule,
            agency: this.userData.agency,
            creditor_website: this.userData.creditor_website,
            average_trade_percentage: this.userData.average_trade_percentage,
            interest_rate: this.userData.interest_rate,
            send_types: this.userData.send_types,
            payment_type: this.userData.payment_type,
          });
          this.$emit("update:is-add-new-user-sidebar-active", false);
          this.resetuserData();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "The creditor has been a successful creator."
          );
          this.A_UPDATE_TABLE_CREDITORS(true);
          setTimeout(() => {
            this.A_UPDATE_TABLE_CREDITORS(false);
          }, 1000);
          this.$emit("saveCreditor");
        } catch (error) {
          this.shoeErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 70rem !important;
}
.b-sidebar-header {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  .close {
    margin-right: revert !important;
  }
}
</style>

<template>
  <div>
    <add-new-creditor
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :key="randomKey"
      @update_key="randomKey = Math.random()"
      @saveLead="keyCreateList = Math.random()"
    />
    <header-slot>
      <template #actions>
        <div>
          <b-button
            v-if="!isOnlyLead"
            variant="success"
            class="mr-1"
            @click="isAddNewUserSidebarActive = true"
          >
            <feather-icon
              icon="PlusIcon"
              size="15"
              class="mr-50 text-white"
            />Create
          </b-button>
        </div>
      </template>
    </header-slot>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AddNewCreditor from "./components/sidebar/AddNewCreditor.vue";
export default {
  components: {
    AddNewCreditor,
  },
  data() {
    return {
      isOnlyLead: false,
      isAddNewUserSidebarActive: false,
      keyCreateList: 0,
      randomKey: Math.random(),
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      skin: "appConfig/skin",
    }),
    routeModule() {
      return this.$route.meta.route;
    },
    isCreditorRoute() {
      return this.$route.path === `/${this.routeModule}/creditors/`;
    },
  },
};
</script>
<style lang="scss">
.vue-toggle {
  margin-top: 0 !important;
}
.card {
  box-shadow: 4px 4px 24px 4px rgb(34, 41, 47, 0.1) !important;
}
.dark-layout .card {
  box-shadow: 0 1px 3px 0 rgb(255 255 255 / 0.1),
    0 1px 2px -1px rgb(255 255 255 / 0.1) !important;
  background: #222222 !important;
}
.bigger {
  margin-top: 5px;
  width: 2em;
  height: 2em;
  cursor: pointer;
}
</style>
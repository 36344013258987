<template>
  <b-container fluid class="p-0">
    <b-card no-body class="py-1">
      <b-container>
        <h3>Basic Information</h3>
        <b-row class="mt-1">
          <b-col>
            <b-form-group label="Creditor Parent" label-for="creditorParent">
              <vue-autosuggest
                ref="autocomplete"
                :suggestions="filteredOptions"
                :get-suggestion-value="getSuggestionValue"
                v-model="userData.creditorParent"
                :input-props="{
                  id: 'creditorParent',
                  class: 'form-control',
                }"
                :limit="10"
                @input="searchCreditorParent"
                @selected="selectHandler"
              >
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item">
                    {{ suggestion.item.value }}
                  </span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col>
            <validation-provider
              name="Creditor Name"
              rules="required|max:255"
              v-slot="{ errors }"
            >
              <b-form-group label="Creditor Name" label-for="creditorName">
                <b-form-input
                  id="creditorName"
                  type="text"
                  v-model="userData.creditorName"
                  name="creditorName"
                  autofocus
                  maxlength="255"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- creditor address -->
        <b-row>
          <b-col>
            <validation-provider v-slot="{ errors }" rules="max:200">
              <b-form-group label="Address">
                <vue-google-autocomplete
                  id="address_principal"
                  ref="addressprincipal"
                  class="form-control input-form"
                  placeholder="Please type your address"
                  country="us"
                  v-model="userData.address"
                  @placechanged="getAddressData"
                  :style="errors[0] ? 'border:1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :state="errors[0] ? false : null"
                  style="height: 30px !important"
                ></vue-google-autocomplete>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- creditor addres city -->
          <b-col sm="6">
            <validation-provider v-slot="{ errors }" rules="max:50">
              <b-form-group label="City" label-for="city">
                <b-form-input
                  id="city_address"
                  maxlength="50"
                  autofocus
                  trim
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  name="city_address"
                  v-model="userData.city_address"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- creditor address state-->
          <b-col sm="6">
            <validation-provider
              name="state"
              rules="integer"
              v-slot="{ errors }"
            >
              <b-form-group label="State" label-for="state">
                <v-select
                  id="state_address"
                  label="state"
                  :options="G_STATUS_EEUU"
                  :reduce="(el) => el.id"
                  v-model="userData.state_address"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- creditor address zip code-->
          <b-col sm="6">
            <validation-provider v-slot="{ errors }" rules="max:10">
              <b-form-group label="Zip code" label-for="zipcode">
                <b-form-input
                  maxlength="10"
                  autofocus
                  id="zipcode_address"
                  name="zipcode_address"
                  ref="zipcode_address"
                  v-model="userData.zip_code_address"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :style="errors[0] ? 'border:1px solid red !important;' : ''"
                  :state="errors[0] ? false : null"
                  v-mask="'######'"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- creditor country -->
          <b-col sm="6">
            <validation-provider
              name="country"
              rules="integer"
              v-slot="{ errors }"
            >
              <b-form-group label="Country" label-for="country">
                <v-select
                  id="country_address"
                  label="name"
                  :options="G_COUNTRIES"
                  :reduce="(el) => el.id"
                  v-model="userData.country_address"
                  :style="errors[0] ? 'border:1px solid red !important;' : ''"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- creditor mailing address -->
        <b-row>
          <b-col>
            <validation-provider v-slot="{ errors }">
              <b-form-group label="Mailing Address">
                <vue-google-autocomplete
                  id="address_principal_2"
                  ref="addressprincipal2"
                  class="form-control input-form"
                  placeholder="Please type your address"
                  country="us"
                  v-model="userData.creditorMailingAddress"
                  @placechanged="getAddressData2"
                  style="height: 30px !important"
                  :style="errors[0] ? 'border:1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :state="errors[0] ? false : null"
                ></vue-google-autocomplete>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- creditor mailing city -->
          <b-col sm="6">
            <validation-provider v-slot="{ errors }" rules="max:50">
              <b-form-group label="City" label-for="city">
                <b-form-input
                  id="city"
                  name="city"
                  maxlength="50"
                  v-model="userData.creditorCity"
                  autofocus
                  trim
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- creditor mailing state -->
          <b-col sm="6">
            <validation-provider v-slot="{ errors }" rules="integer">
              <b-form-group label="State" label-for="state">
                <v-select
                  id="state"
                  label="state"
                  :options="G_STATUS_EEUU"
                  :reduce="(el) => el.id"
                  v-model="userData.creditorState"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- creditor mailing zip code -->
          <b-col sm="6">
            <validation-provider v-slot="{ errors }" rules="max:10">
              <b-form-group label="Zip code" label-for="zipcode">
                <b-form-input
                  id="zipcode"
                  name="zipcode"
                  maxlength="10"
                  autofocus
                  v-model="userData.creditorZipCode"
                  trim
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  v-mask="'######'"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- creditor mailing country -->
          <b-col sm="6">
            <validation-provider rules="integer" v-slot="{ errors }">
              <b-form-group label="Country" label-for="country">
                <v-select
                  id="country"
                  label="name"
                  :options="G_COUNTRIES"
                  :reduce="(el) => el.id"
                  v-model="userData.creditorCountry"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card no-body class="py-1 mt-2">
      <b-container>
        <h3>Recovery</h3>

        <!-- recovery name -->
        <b-row class="mt-1">
          <b-col>
            <validation-provider rules="max:255" v-slot="{ errors }">
              <b-form-group label="Name" label-for="recovery">
                <div class="paddingT">
                  <b-form-input
                    id="recovery"
                    name="recovery"
                    maxlength="255"
                    v-model="userData.recoveryName"
                    autofocus
                    trim
                    :state="errors[0] ? false : null"
                  />
                </div>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        
        <b-row class="mt-1">
          <!-- recovery phone -->
          <b-col cols="4">
            <validation-provider v-slot="{ errors }" :rules="userData.recovery_phone.length > 1 ? 'mobile|required':'mobile'">
              <div class="d-flex justify-content-between">
                <label for="recoveryPhone">Phone</label>
                <b-button
                  variant="outline"
                  v-b-tooltip.hover-right="'Add Recovery Phone'"
                  class="p-0 m-0"
                  :disabled="currentPhoneRecoveryHasData"
                  @click="addInput(1)"
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    size="18"
                    class="text-success"
                  />
                </b-button>
              </div>
              <b-form-input
                id="recoveryPhone"
                v-model="userData.recovery_phone[0].phone"
                autofocus
                trim
                v-mask="'(###) ###-####'"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-col>
          <!-- recovery agent -->
          <b-col cols="4">
            <validation-provider v-slot="{ errors }" :rules="userData.recovery_agent.length >1 ? 'required|min:2|max:255':'min:2|max:255'">
              <div class="d-flex justify-content-between">
                <label for="recoveryPhoneAgent">Agent</label>
                <b-button
                  variant="outline"
                  v-b-tooltip.hover-right="'Add Recovery Agent'"
                  :disabled="currentAgentRecoveryHasData"
                  class="p-0 m-0"
                  @click="addInput(7)"
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    size="18"
                    class="text-success"
                  />
                </b-button>
              </div>
              <b-form-input
                id="recoveryAgent"
                maxlength="255"
                v-model="userData.recovery_agent[0].agent"
                autofocus
                trim
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-col>
          <!-- recovery extension -->
          <b-col cols="4">
            <validation-provider v-slot="{ errors }" rules="min:2">
              <label for="recoveryPhoneExtension">Extension</label>
              <b-form-input
                id="recoveryExtension"
                v-model="userData.recovery_agent[0].extension"
                autofocus
                trim
                v-mask="'/ ######'"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- list of recovery phone and agent -->
        <b-row>
          <b-col cols="4" v-if="userData.recovery_phone.length > 1">
            <b-row
              v-for="(item, index) in userData.recovery_phone"
              :key="index"
            >
              <b-col v-if="index > 0" class="mt-1">
                <div class="d-flex justify-content-between">
                  <label for="recoveryPhone">Phone</label>
                  <b-button
                    variant="outline"
                    v-b-tooltip.hover-right="'Delete Recovery Phone'"
                    class="p-0 m-0"
                    @click="deleteInput(1, index)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="18"
                      class="text-danger"
                    />
                  </b-button>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="mobile|required"
                  tag="div"
                >
                  <b-form-input
                    id="recoveryPhone"
                    v-model="userData.recovery_phone[index].phone"
                    autofocus
                    trim
                    :state="errors[0] ? false : null"
                    v-mask="'(###) ###-####'"
                  />
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4" v-else></b-col>

          <b-col cols="8" v-if="userData.recovery_agent.length > 1">
            <b-row
              v-for="(item, index) in userData.recovery_agent"
              :key="index"
            >
              <b-col cols="6"  v-if="index > 0" class="mt-1">
                <div class="d-flex justify-content-between">
                  <label for="recoveryAgent">Agent</label>
                  <b-button
                    variant="outline"
                    v-b-tooltip.hover-right="'Delete Recovery Agent'"
                    class="p-0 m-0"
                    @click="deleteInput(7, index)"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="18"
                      class="text-danger"
                    />
                  </b-button>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="min:2|max:255|required"
                  tag="div"
                >
                  <b-form-input
                    id="recoveryAgent"
                    maxlength="255"
                    v-model="userData.recovery_agent[index].agent"
                    autofocus
                    trim
                    :state="errors[0] ? false : null"
                  />
                </validation-provider>
              </b-col>

              <b-col cols="6" v-if="index > 0" class="mt-1">
                <div class="d-flex justify-content-between">
                  <label for="recoveryExtension">Extension</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="min:2"
                  tag="div"
                >
                  <b-form-input
                    id="recoveryExtension"
                    v-model="userData.recovery_agent[index].extension"
                    autofocus
                    trim
                    :state="errors[0] ? false : null"
                    v-mask="'/ ######'"
                  />
                </validation-provider>
              </b-col>
            </b-row> 
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- recovery fax -->
          <b-col cols="4">
            <div class="d-flex justify-content-between">
              <label>Fax</label>
              <b-button
                variant="outline"
                class="p-0 paddingB"
                :disabled="isDisabledFax || currentFaxRecoveryHasData"
                @click="addInput(2)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="18"
                  class="mr-50 text-primary"
                />
              </b-button>
            </div>
            <validation-provider v-slot="{ errors }" rules="mobile">
              <b-form-input
                class="mr-1"
                id="recoveryFax"
                v-model="userData.recoveryFax[0].fax"
                name="recoveryFax"
                autofocus
                v-mask="'(###) ###-####'"
                :state="errors[0] ? false : null"
              />
            </validation-provider>

            <!-- list of recovery fax  -->
            <div v-show="showFax">
              <b-row v-for="(item, index) in userData.recoveryFax" :key="index">
                <b-col class="mt-1 ml-1 p-0" v-if="index > 0">
                  <validation-provider
                    rules="mobile|required"
                    v-slot="{ errors }"
                  >
                    <div class="d-flex justify-content-between">
                      <b-form-input
                        v-model="userData.recoveryFax[index].fax"
                        autofocus
                        :state="errors[0] ? false : null"
                        v-mask="'(###) ###-####'"
                      />
                      <div class="paddingIconoX">
                        <b-button
                          variant="outline"
                          class="p-0 m-0"
                          @click="deleteInput(2, index)"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            size="18"
                            class="text-danger"
                          />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col cols="8">
            <!-- recovery email -->

            <validation-provider rules="email|max:255" v-slot="{ errors }">
              <div class="d-flex justify-content-between">
                <label>Email</label>
                <b-button
                  variant="outline"
                  class="p-0 paddingB"
                  :disabled="isDisabledEmail || currentEmailRecoveryHasData"
                  @click="addInput(3)"
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    size="18"
                    class="mr-50 text-primary"
                  />
                </b-button>
              </div>
              <b-form-input
                v-model="userData.recoveryEmail[0].email"
                type="email"
                autofocus
                :state="errors[0] ? false : null"
              />
            </validation-provider>

            <!-- list of recovery email -->
            <div v-show="showEmail">
              <b-row
                v-for="(item, index) in userData.recoveryEmail"
                :key="index"
              >
                <b-col class="mt-1 ml-1 p-0" v-if="index > 0">
                  <validation-provider
                    rules="email|required|max:255"
                    v-slot="{ errors }"
                  >
                    <div class="d-flex justify-content-between">
                      <b-form-input
                        v-model="userData.recoveryEmail[index].email"
                        autofocus
                        :state="errors[0] ? false : null"
                      />
                      <div class="paddingIconoX">
                        <b-button
                          variant="outline"
                          class="p-0 m-0"
                          @click="deleteInput(3, index)"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            size="18"
                            class="text-danger"
                          />
                        </b-button>
                      </div>
                    </div>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <!-- <b-col cols="6">
            <b-form-group label="Agent" label-for="agent">
              <div class="paddingT">
                <validation-provider v-slot="{ errors }">
                  <b-form-input
                    v-model="userData.agent"
                    autofocus
                    :state="errors[0] ? false : null"
                  />
                </validation-provider>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Offer Contact Number" label-for="ofc">
              <div class="paddingT">
                <validation-provider v-slot="{ errors }">
                  <b-form-input
                    v-model="userData.offer_contact_number"
                    autofocus
                    :state="errors[0] ? false : null"
                    v-mask="'(###) ###-####'"
                  />
                </validation-provider>
              </div>
            </b-form-group>
          </b-col> -->

          <!-- % Min of settlement -->
          <b-col cols="6">
            <validation-provider v-slot="{ errors }">
              <b-form-group label="% Min of settlement" label-for="settlement">
                <b-form-input
                  v-model="userData.customerSettlement"
                  v-mask="'##.##'"
                  :state="errors[0] ? false : null"
                  autofocus
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- settle with A.M.G -->
          <b-col cols="2" class="d-flex justify-content-center">
            <b-form-group
              label="Settle with A.M.G"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group class="paddingT">
                <b-form-radio
                  disabled
                  v-model="userData.customerSettle"
                  :aria-describedby="ariaDescribedby"
                  value="1"
                  >Yes</b-form-radio
                >
                <b-form-radio
                  disabled
                  v-model="userData.customerSettle"
                  :aria-describedby="ariaDescribedby"
                  value="2"
                  >No</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card no-body class="py-1 mt-2">
      <b-container>
        <h3>Customer</h3>
        <!-- customer name  -->
        <!-- <b-row class="mt-1">
          <b-col>
            <validation-provider rules="max:255" v-slot="{ errors }">
              <b-form-group label="Name" label-for="customer_name">
                <b-form-input
                  v-model="userData.customerName"
                  autofocus
                  maxlength="255"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row> -->

        <b-row class="mt-1">
          <!-- customer phone -->
          <b-col
            v-for="(item, index) in userData.customerPhone"
            cols="2"
            class="mr-0 pr-0"
            :key="index"
          >
            <validation-provider
              :rules="index !== 0 ? 'mobile|required' : 'mobile'"
              v-slot="{ errors }"
            >
              <div class="d-flex justify-content-between">
                <label>Phone </label>
                <!-- ADD BUTTON  -->
                <template
                  v-if="index == 0 && userData.customerPhone.length < 5"
                >
                  <b-button
                    v-if="
                      isDisabledPhoneCustomer || currentPhoneCustomerHasData
                    "
                    variant="outline"
                    class="p-0"
                    v-b-tooltip.hover-right="
                      'You need to fill the previous phone number before adding a new one'
                    "
                  >
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="18"
                      class="text-primary"
                    />
                  </b-button>
                  <b-button
                    v-else
                    variant="outline"
                    class="p-0"
                    :disabled="
                      isDisabledPhoneCustomer || currentPhoneCustomerHasData
                    "
                    @click="addInput(4)"
                  >
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="18"
                      class="text-primary"
                    />
                  </b-button>
                </template>
                <!-- DELETE BUTTON -->
                <b-button
                  v-if="index !== 0"
                  variant="outline"
                  class="p-0 m-0"
                  @click="deleteInput(4, index)"
                >
                  <feather-icon
                    icon="XSquareIcon"
                    size="18"
                    class="text-danger"
                  />
                </b-button>
              </div>
              <b-form-input
                v-model="userData.customerPhone[index].phone"
                :autofocus="index !== 0 ? true : false"
                :state="errors[0] ? false : null"
                v-mask="'(###) ###-####'"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <!-- customer fax -->
          <b-col
            v-for="(item, index) in userData.customerFax"
            cols="2"
            class="mr-0 pr-0"
            :key="index"
          >
            <validation-provider
              :rules="index !== 0 ? 'mobile|required' : 'mobile'"
              v-slot="{ errors }"
            >
              <div class="d-flex justify-content-between">
                <label>Fax </label>
                <!-- ADD BUTTON  -->
                <template v-if="index == 0 && userData.customerFax.length < 5">
                  <b-button
                    v-if="isDisabledFaxCustomer || currentFaxCustomerHasData"
                    variant="outline"
                    class="p-0"
                    v-b-tooltip.hover-right="
                      'You need to fill the previous fax before adding a new one'
                    "
                  >
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="18"
                      class="text-primary"
                    />
                  </b-button>
                  <b-button
                    v-else
                    variant="outline"
                    class="p-0"
                    @click="addInput(6)"
                  >
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="18"
                      class="text-primary"
                    />
                  </b-button>
                </template>

                <!-- DELETE BUTTON -->
                <b-button
                  v-if="index !== 0"
                  variant="outline"
                  class="p-0 m-0"
                  @click="deleteInput(6, index)"
                >
                  <feather-icon
                    icon="XSquareIcon"
                    size="18"
                    class="text-danger"
                  />
                </b-button>
              </div>
              <b-form-input
                :state="errors[0] ? false : null"
                v-model="userData.customerFax[index].fax"
                :autofocus="index !== 0 ? true : false"
                v-mask="'(###) ###-####'"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <!-- customer email -->
          <b-col
            v-for="(item, index) in userData.customerEmail"
            cols="2"
            class="mr-0 pr-0"
            :key="index"
          >
            <validation-provider
              :rules="index !== 0 ? 'email|max:255|required' : 'email|max:255'"
              v-slot="{ errors }"
            >
              <div class="d-flex justify-content-between">
                <label>Email </label>
                <!-- ADD BUTTON  -->
                <template
                  v-if="index == 0 && userData.customerEmail.length < 5"
                >
                  <b-button
                    v-if="
                      isDisabledEmailCustomer || currentEmailCustomerHasData
                    "
                    variant="outline"
                    class="p-0"
                    v-b-tooltip.hover-right="
                      'You need to fill the previous email before adding a new one'
                    "
                  >
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="18"
                      class="text-primary"
                    />
                  </b-button>
                  <b-button
                    v-else
                    variant="outline"
                    class="p-0"
                    :disabled="
                      isDisabledEmailCustomer || currentEmailCustomerHasData
                    "
                    @click="addInput(5)"
                  >
                    <feather-icon
                      icon="PlusSquareIcon"
                      size="18"
                      class="text-primary"
                    />
                  </b-button>
                </template>

                <!-- DELETE BUTTON -->
                <b-button
                  v-if="index !== 0"
                  variant="outline"
                  class="p-0 m-0"
                  @click="deleteInput(5, index)"
                >
                  <feather-icon
                    icon="XSquareIcon"
                    size="18"
                    class="text-danger"
                  />
                </b-button>
              </div>
              <b-form-input
                v-model="userData.customerEmail[index].email"
                :autofocus="index !== 0 ? true : false"
                type="email"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <!-- customer website -->
          <b-col cols="12">
            <validation-provider v-slot="{ errors }">
              <b-form-group label="Website" label-for="website">
                <b-form-input
                  v-model="userData.customerWeb"
                  :state="errors[0] ? false : null"
                  autofocus
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-container>
</template>
<script>
import { VueAutosuggest } from "vue-autosuggest";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CreditorsService from "../../service/creditors.service";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  name: "BasicInformationCreditors",
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // set Settle with A.M.G false by default
    this.userData.customerSettle = 2;
  },
  watch: {
    "userData.recovery_phone": {
      handler: function (val) {
        let length = val.length;
        let last_item = val[length - 1];
        if (
          last_item.phone != null &&
          last_item.phone != "" &&
          last_item.phone.length == 14
        ) {
          this.currentPhoneRecoveryHasData = false;
        } else {
          this.currentPhoneRecoveryHasData = true;
        }
      },
      deep: true,
    },
    "userData.recovery_agent": {
      handler: function (val) {
        let length = val.length;
        let last_item = val[length - 1];
        if (
          last_item.agent != null &&
          last_item.agent != "" &&
          last_item.agent.length >= 2
        ) {
          this.currentAgentRecoveryHasData = false;
        } else {
          this.currentAgentRecoveryHasData = true;
        }
      },
      deep: true,
    },
    "userData.recoveryFax": {
      handler: function (val) {
        let length = val.length;
        let last_item = val[length - 1];
        if (last_item.fax != null && last_item.fax != "") {
          this.currentFaxRecoveryHasData = false;
        } else {
          this.currentFaxRecoveryHasData = true;
        }
      },
      deep: true,
    },
    "userData.recoveryEmail": {
      handler: function (val) {
        let length = val.length;
        let last_item = val[length - 1];
        if (last_item.email != null && last_item.email != "") {
          this.currentEmailRecoveryHasData = false;
        } else {
          this.currentEmailRecoveryHasData = true;
        }
      },
      deep: true,
    },
    "userData.customerPhone": {
      handler: function (val) {
        let length = val.length;
        let last_item = val[length - 1];
        if (last_item.phone != null && last_item.phone != "") {
          this.currentPhoneCustomerHasData = false;
        } else {
          this.currentPhoneCustomerHasData = true;
        }
      },
      deep: true,
    },
    "userData.customerFax": {
      handler: function (val) {
        let length = val.length;
        let last_item = val[length - 1];
        if (last_item.fax != null && last_item.fax != "") {
          this.currentFaxCustomerHasData = false;
        } else {
          this.currentFaxCustomerHasData = true;
        }
      },
      deep: true,
    },
    "userData.customerEmail": {
      handler: function (val) {
        let length = val.length;
        let last_item = val[length - 1];
        if (last_item.email != null && last_item.email != "") {
          this.currentEmailCustomerHasData = false;
        } else {
          this.currentEmailCustomerHasData = true;
        }
      },
      deep: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    VueGoogleAutocomplete,
    vSelect,
  },

  computed: {
    filterSearch() {
      return this.creditorParent == "" ? false : true;
    },
    ...mapGetters({
      G_STATUS_EEUU: "DebtSolutionCreditors/G_STATUS_EEUU",
      G_COUNTRIES: "DebtSolutionCreditors/G_COUNTRIES",
    }),
  },
  data() {
    return {
      currentPhoneRecoveryHasData: true,
      currentAgentRecoveryHasData: true,
      currentFaxRecoveryHasData: true,
      currentEmailRecoveryHasData: true,
      currentPhoneCustomerHasData: true,
      currentEmailCustomerHasData: true,
      currentFaxCustomerHasData: true,
      required,
      email,
      dataCreditor: [],
      filteredOptions: [],
      creditorParent: "",
      isDisabledFax: false,
      isDisabledEmail: false,
      isDisabledPhoneCustomer: false,
      isDisabledEmailCustomer: false,
      isDisabledFaxCustomer: false,
      showPhone: false,
      showFax: false,
      showEmail: false,
      showPhoneCustomer: false,
      showEmailCustomer: false,
      showFaxCustomer: false,
      dataPhone: [],
      dataFax: [],
      dataEmail: [],
      dataPhoneCustoner: [],
    };
  },
  methods: {
    async searchCreditorParent(text) {
      this.userData.creditorName = null;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        return;
      }
      try {
        const data = await CreditorsService.GET_CREDITORS_PARENTS({ q: text });
        this.filteredOptions = [{ data: data }];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    selectHandler(data) {
      this.user_id = data.item.id;
      this.userData.creditorParentId = data.item.id;
      this.userData.creditorParent = data.item.value;
      this.users = null;
      this.statusSelected = false;
    },
    getSuggestionValue(suggestion) {
      this.userData.creditorParent = suggestion.item.id;
      return suggestion.item.value;
    },
    addInput(data) {
      switch (data) {
        case 1:
          this.userData.recovery_phone.push({phone:""});
          break;
        case 2:
          this.showFax = true;
          this.userData.recoveryFax.push({});
          if (this.userData.recoveryFax.length >= 5) {
            this.isDisabledFax = true;
          }
          break;
        case 3:
          this.showEmail = true;
          this.userData.recoveryEmail.push({});
          if (this.userData.recoveryEmail.length >= 5) {
            this.isDisabledEmail = true;
          }
          break;
        case 4:
          this.showPhoneCustomer = true;
          this.userData.customerPhone.push({});
          if (this.userData.customerPhone.length >= 5) {
            this.isDisabledPhoneCustomer = true;
          }
          break;
        case 5:
          this.showEmailCustomer = true;
          this.userData.customerEmail.push({});
          if (this.userData.customerEmail.length >= 5) {
            this.isDisabledEmailCustomer = true;
          }
          break;
        case 6:
          this.showFaxCustomer = true;
          this.userData.customerFax.push({});
          if (this.userData.customerFax.length >= 5) {
            this.isDisabledFaxCustomer = true;
          }
          break;
        case 7:
          this.userData.recovery_agent.push({ agent: "", extension: "" });
          break;
        default:
          break;
      }
    },
    deleteInput(data, index) {
      switch (data) {
        case 1:
          for (var i = 0; i < this.userData.recovery_phone.length; i++) {
            if (i == index) {
              this.userData.recovery_phone.splice(i, 1);
            }
          }
          break;
        case 2:
          this.isDisabledFax = false;
          for (var i = 0; i < this.userData.recoveryFax.length; i++) {
            if (i == index) {
              this.userData.recoveryFax.splice(i, 1);
            }
          }
          break;
        case 3:
          this.isDisabledEmail = false;
          for (var i = 0; i < this.userData.recoveryEmail.length; i++) {
            if (i == index) {
              this.userData.recoveryEmail.splice(i, 1);
            }
          }
          break;
        case 4:
          this.isDisabledPhoneCustomer = false;
          for (var i = 0; i < this.userData.customerPhone.length; i++) {
            if (i == index) {
              this.userData.customerPhone.splice(i, 1);
            }
          }
          break;
        case 5:
          this.isDisabledEmailCustomer = false;
          for (var i = 0; i < this.userData.customerEmail.length; i++) {
            if (i == index) {
              this.userData.customerEmail.splice(i, 1);
            }
          }
          break;

        case 6:
          this.isDisabledFaxCustomer = false;
          for (var i = 0; i < this.userData.customerFax.length; i++) {
            if (i == index) {
              this.userData.customerFax.splice(i, 1);
            }
          }
          break;
        case 7:
          for (var i = 0; i < this.userData.recovery_agent.length; i++) {
            if (i == index) {
              this.userData.recovery_agent.splice(i, 1);
            }
          }
          break;
        default:
          break;
      }
    },
    capitalize(el) {
      const element = this.userData[el];
      this.userData[el] =
        element.substr(0, 1).toUpperCase() + element.substr(1);
    },
    getAddressData(data, placeResultData, id) {
      this.userData.address = placeResultData.formatted_address;
      this.$refs.addressprincipal.$el.value = placeResultData.formatted_address;

      this.userData.zip_code_address = data.postal_code;
      this.userData.city_address = data.locality;
      this.userData.state_address = this.G_STATUS_EEUU.find(
        (e) => e.slug === data.administrative_area_level_1
      ).id;
      this.userData.country_address = this.G_COUNTRIES.find(
        (e) => e.name.toLowerCase() == "estados unidos"
      ).id;
    },
    getAddressData2(data, placeResultData, id) {
      this.$refs.addressprincipal2.$el.value = `${data.street_number || ""} ${
        data.route
      }`;
      this.userData.creditorMailingAddress = `${
        data.street_number ? data.street_number : ""
      } ${data.route}`;
      this.userData.creditorCity = data.locality;
      this.userData.creditorZipCode = data.postal_code;
      this.userData.creditorState = this.G_STATUS_EEUU.find(
        (e) => e.slug === data.administrative_area_level_1
      ).id;
      this.userData.creditorCountry = this.G_COUNTRIES.find(
        (e) => e.name.toLowerCase() == "estados unidos"
      ).id;
    },
  },
};
</script>
<style lang="scss">
.paddingB {
  padding-bottom: 4px !important;
}
.paddingT {
  padding-top: 4px !important;
}
.ppaddingTP {
  padding-top: 23px !important;
}
.paddingIconoX {
  padding-top: 8px !important;
  padding-left: 3px !important;
}
.widthInput {
  width: 96%;
}
.widthInputPhone {
  width: 80%;
}
</style>

<template>
  <div>
    <b-card no-body class="py-1 mt-2">
      <b-container>
        <h3>More Information</h3>
        <b-row>
          <b-col sm="6">
            <validation-provider v-slot="{ errors }">
              <b-form-group label="Type" label-for="type">
                <v-select
                  label="value"
                  :options="types"
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :reduce="(el) => el.id"
                  v-model="userData.debt_types"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :preserve-search="true"
                  placeholder="Pick some"
                  class="rounded"
                  :preselect-first="true"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col sm="6">
            <validation-provider rules="integer" v-slot="{ errors }">
              <b-form-group label="Timezone" label-for="timezone">
                <v-select
                  :style="errors[0] ? 'border: 1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                  :options="timezones"
                  label="name"
                  :reduce="(el) => el.id"
                  v-model="userData.timezone_id"
                  class="rounded"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          <b-col sm="6">
            <validation-provider name="payment_types">
              <b-form-group label="Payment Type" label-for="payment_types">
                <v-select
                  label="value"
                  :options="payment_types"
                  :reduce="(el) => el.id"
                  v-model="userData.payment_type"
                  class="rounded"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider name="send_type">
              <b-form-group label="Send Type" label-for="send_type">
                <v-select
                  label="name"
                  :options="send_types"
                  :reduce="(el) => el.id"
                  v-model="userData.send_types"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :preserve-search="true"
                  placeholder="Pick some"
                  class="rounded"
                  :preselect-first="true"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col sm="6">
            <validation-provider
              name="agency"
              rules="max:255"
              v-slot="{ errors }"
            >
              <b-form-group label="Agency" label-for="agency">
                <b-form-input
                  v-model="userData.agency"
                  class="rounded"
                  maxlength="255"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          <!-- <b-col sm="6">
            <validation-provider
              name="website"
              rules="max:255"
              v-slot="{ errors }"
            >
              <b-form-group label="Website" label-for="website">
                <b-form-input
                  id="website"
                  v-model="userData.creditor_website"
                  class="rounded"
                  :state="errors[0] ? false : null"
                  maxlength="255"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col> -->

          <b-col sm="6">
            <validation-provider name="average_trade_percentage">
              <b-form-group
                label="Average Settlement Percentage"
                label-for="average_trade_percentage"
              >
                <b-form-input
                  id="average_trade_percentage"
                  v-model="userData.average_trade_percentage"
                  class="rounded"
                  v-mask="'##.##'"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col sm="6">
            <validation-provider name="interest_rate">
              <b-form-group label="Interest Rate" label-for="interest_rate">
                <b-form-input
                  id="interest_rate"
                  v-model="userData.interest_rate"
                  class="rounded"
                  v-mask="'##.##'"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- <b-row class="mt-1">
          <b-col>
            <b-form-group label="Address">
              <vue-google-autocomplete
                :key="userData.contadpr + 1"
                id="address_principal4"
                ref="addressprincipal4"
                class="form-control input-form"
                placeholder="Please type your address"
                country="us"
                v-model="userData.address1"
                @placechanged="getAddressData1"
                style="height: 30px !important"
              ></vue-google-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <validation-provider name="address">
              <b-form-group label="Mailing Address">
                <vue-google-autocomplete
                  :key="userData.contador"
                  id="address_principal_5"
                  ref="addressprincipal5"
                  class="form-control input-form"
                  placeholder="Please type your address"
                  country="us"
                  v-model="userData.creditorAddress"
                  @placechanged="getAddressData2"
                  style="height: 30px !important"
                ></vue-google-autocomplete>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <validation-provider name="city" rules="alpha_spaces">
              <b-form-group label="City" label-for="city">
                <b-form-input
                  id="city"
                  name="city"
                  v-model="userData.customerCity1"
                  autofocus
                  trim
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider name="state" rules="integer">
              <b-form-group label="State" label-for="state">
                <v-select
                  id="state"
                  label="state"
                  :options="G_STATUS_EEUU"
                  :reduce="(el) => el.id"
                  v-model="userData.customerState1"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <validation-provider name="zipcode">
              <b-form-group label="Zip code" label-for="zipcode">
                <b-form-input
                  id="zipcode"
                  name="zipcode"
                  autofocus
                  v-model="userData.customerZipcode1"
                  trim
                  v-mask="'######'"
                ></b-form-input>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider name="country" rules="integer">
              <b-form-group label="Country" label-for="country">
                <v-select
                  id="country"
                  label="name"
                  :options="G_COUNTRIES"
                  :reduce="(el) => el.id"
                  v-model="userData.customerCountry1"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row> -->
      </b-container>
    </b-card>

    <b-card no-body class="py-1 mt-2">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="d-flex justify-content-between">
              <h3>Contact Schedule</h3>

              <b-button
                variant="outline-success"
                size="sm"
                class="rounded"
                @click="validateBeforeAddToSchedule"
              >
                <feather-icon size="15" icon="PlusIcon" />
                Add Schedule
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-container>
          <b-row>
            <b-col cols="5">
              <b-form-group label="Agency">
                <b-form-input
                  id="agency"
                  v-model="contactScheduleObject.agency"
                  class="rounded"
                  maxlength="255"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group label="Timezone">
                <v-select
                  id="type"
                  label="name"
                  :options="timezones"
                  :reduce="(el) => el.id"
                  v-model="contactScheduleObject.timezone_id"
                  class="rounded"
                ></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group label="From">
                <kendo-timepicker
                  :format="'HH:mm'"
                  v-model="contactScheduleObject.time_from"
                  :interval="15"
                  class="w-100 rounded bg-transparent"
                  placeholder="Time from"
                  style="height: 2.73rem"
                  :disabled="valorEdit"
                />
              </b-form-group>
            </b-col>
            <b-col cols="5">
              <b-form-group label="To">
                <template #label>
                  <span>To</span>
                </template>
                <kendo-timepicker
                  :format="'HH:mm'"
                  v-model="contactScheduleObject.time_to"
                  :interval="15"
                  class="w-100 rounded bg-transparent"
                  placeholder="Time from"
                  style="height: 2.73rem"
                  :disabled="valorEdit"
                  :min="minTime"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="12">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-center">Agency</th>
                    <th class="text-center">From</th>
                    <th class="text-center">To</th>
                    <th class="text-center">Timezone</th>
                  </tr>
                </thead>
                <tbody v-if="schedule.length > 0">
                  <template v-for="(item, index) in schedule">
                    <tr :key="index">
                      <td class="text-center">{{ item.agency }}</td>
                      <td class="text-center">{{ item.time_from }}</td>
                      <td class="text-center">{{ item.time_to }}</td>
                      <td class="text-center">
                        {{
                          timezones.find((el) => el.id === item.timezone_id)
                            .name
                        }}
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4" class="text-center">No schedules added</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
import ContactCreditor from "@/views/commons/components/first-notes/ClientSchedule.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import CreditorsService from "@/views/debt-solution/views/creditors/service/creditors.service.js";

export default {
  name: "MoreInformationCreditors",
  props: {
    userData: {
      type: Object,
      required: true,
      ContactCreditor,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    VueGoogleAutocomplete,
    vSelect,
    ContactCreditor,
  },

  computed: {
    ...mapGetters({
      G_STATUS_EEUU: "DebtSolutionCreditors/G_STATUS_EEUU",
      G_COUNTRIES: "DebtSolutionCreditors/G_COUNTRIES",
    }),
    minTime() {
      let dTime = this.contactScheduleObject.time_from || "00:00";
      let contactDtime = "01/01/1970 " + dTime;
      var date = new Date(contactDtime);
      return date;
    },
    groupScheduleByDay() {
      return this.schedule.reduce((r, a) => {
        r[a.date] = [...(r[a.date] || []), a];
        return r;
      }, {});
    },
  },
  data() {
    return {
      moment: moment,
      types: [],
      timezones: [],
      send_types: [],
      payment_types: [
        {
          id: 1,
          value: "E-CHECK (CHECKING ACCOUNT)",
        },
        {
          id: 2,
          value: "CHECK (OVERNIGHT)",
        },
        {
          id: 3,
          value: "CASHIER CHECK",
        },
        {
          id: 4,
          value: "MONEY ORDER",
        },
      ],
      required,
      email,
      creditorParent: "",
      contactScheduleObject: {
        agency: null,
        timezone_id: null,
        time_from: null,
        time_to: null,
      },
      valorEdit: false,
      ds_contact_schedule: [],
      noteInfo: [],
      schedule: [],
    };
  },
  created() {
    this.allTypes();
    this.getTimezones();
    this.getSendTypes();
  },
  watch: {
    schedule: {
      handler: function (val) {
        this.$emit("update:schedule", val);
      },
      deep: true,
    },
  },
  methods: {
    clearFields() {
      this.contactScheduleObject.agency = null;
      this.contactScheduleObject.timezone_id = null;
      this.contactScheduleObject.time_from = null;
      this.contactScheduleObject.time_to = null;
    },
    async getTimezones() {
      try {
        const response = await CreditorsService.getTimezones();
        if (response.status === 200) {
          this.timezones = response.data;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    async getSendTypes() {
      try {
        const response = await CreditorsService.getSendTypes();
        if (response.status === 200) {
          this.send_types = response.data;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    validateBeforeAddToSchedule() {
      if (this.contactScheduleObject.agency == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please fill the agency field"
        );
        return true;
      }
      if (this.contactScheduleObject.timezone_id == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please select a timezone"
        );
        return true;
      }
      if (this.contactScheduleObject.time_from == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please select a time from"
        );
        return true;
      }
      if (this.contactScheduleObject.time_to == null) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Please select a time to"
        );
        return true;
      }

      if (
        this.contactScheduleObject.time_from >=
        this.contactScheduleObject.time_to
      ) {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "Time from must be less than time to"
        );
        return true;
      }
      this.addToSchedule();
    },
    removeSchedule(id) {
      this.schedule = this.schedule.filter((e) => e.id !== id);
    },
    addToSchedule() {
      const obj = { ...this.contactScheduleObject };
      this.schedule.push(obj);
      this.clearFields();
    },
    async allTypes() {
      try {
        const response = await amgApi.get(
          "/sales-made/debt-solution/get-debts-types"
        );
        if (response.status === 200) {
          // id 6, 7 and  8 remove
          this.types = response.data.filter(
            (el) => el.id != 6 && el.id != 7 && el.id != 8
          );
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
  <style lang="scss">
.paddingB {
  padding-bottom: 4px !important;
}
.paddingT {
  padding-top: 4px !important;
}
.ppaddingTP {
  padding-top: 23px !important;
}
.paddingIconoX {
  padding-top: 8px !important;
  padding-left: 3px !important;
}
.widthInput {
  width: 96%;
}
.widthInputPhone {
  width: 80%;
}
</style>
  